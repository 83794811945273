import React from "react";
import styled, { keyframes, css } from "styled-components";
import carImage from "../Assets/img/carImage.png";

const scaleUp = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1.6);
  }
`;
const CursorPointer = styled.div.attrs((props) => ({
  style: {
    left: `${props.x}px`,
    top: `${props.y}px`,
    display: props.visible ? "block" : "none",
  },
}))`
  background-color: white;
  background-image: url(${carImage});
  position: fixed;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  mix-blend0mode: multiply;
  transform: translate(-50%, -50%);
  z-index: 1;
  animation: ${(props) =>
    props.visible &&
    css`
      ${scaleUp} 0.3s ease-out forwards
    `};
`;

export default CursorPointer;
