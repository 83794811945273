import React, { Suspense, lazy, useState, useEffect } from "react";
import "./HowWorks.scss";
import SEO from "../../../Shared/SEO/SEO";
import HowItWork from "../Home/Components/HowItWork";
const AppInfo = lazy(() => import("../Home/Components/AppInfo"));


function HowWorks() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <SEO
        title="Your Eco Friendly Dry Cleaner"
        description="LaundraMoon Aims To Provide Sustainable And Reliable Laundry Service For All"
        imageUrl={require("../../../Assets/img/LaundraMoon.png")}
        keywords="laundry pick up service, same day dry cleaning london, dry cleaners price list, wedding dress dry cleaning cost, leather dry cleaning"
        url={'https://www.laundramoon.com/how-it-works'}
        type="How it Works Page"
        author={"LaundraMoon"}
        publisher={"LaundraMoon"}
      />

      <div id="about">
        {!showContent ? (
          <div style={{ width: "100%", height: "100vh" }}></div>
        ) : (
          <>
            <div className="share-your-visions">
              <span className="share-your-visions-title">
                <h1>How It Works - Laundramoon</h1>
                <p className="title">Your Professional Dry Cleaning & Laundry Service </p>
              </span>
            </div>
            <Suspense>
              <HowItWork/>
              <AppInfo />
            </Suspense>
          </>
        )}
      </div>
    </>
  );
}

export default HowWorks;
