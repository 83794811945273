import React, { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import collection from "../../../../Assets/svgs/How-it-works/collection.svg";
import sorting from "../../../../Assets/svgs/How-it-works/sort.svg";
import Cleaning from "../../../../Assets/svgs/How-it-works/cleaning.svg";
import Ironing from "../../../../Assets/svgs/How-it-works/iron.svg";
import Folding from "../../../../Assets/svgs/How-it-works/folding.svg";
import qualitycheck from "../../../../Assets/svgs/How-it-works/quality.svg";
import inspection from "../../../../Assets/svgs/How-it-works/inspection.svg";
import Packing from "../../../../Assets/svgs/How-it-works/packing.svg";
import Delivery from "../../../../Assets/svgs/How-it-works/delivery.svg";
import car from "../../../../Assets/svgs/track.svg";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import AOS from "aos";
import "aos/dist/aos.css";
import CursorPointer from "../../../../Shared/Cursor";

const HowItWork = ({ zIndexZ, windowWidth }) => {
  AOS.init();
  AOS.init({
    disable: false,
    startEvent: "DOMContentLoaded",
    initClassName: "aos-init",
    animatedClassName: "aos-animate",
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 30,
    throttleDelay: 99,

    offset: 0,
    delay: 0.5,
    duration: 1000,
    easing: "ease",
    once: false,
    mirror: false,
    anchorPlacement: "top-bottom",
  });

  const dataLeft = [
    { holderClass: "img-wrapper", effect: "fade-left", descclass: "desc1", class: "class1", left: true, step: "01", icon: collection, heading: "Collection", desc: "Our team picks up your laundry from your home, office or any preferred location at a scheduled time, ensuring convenience and reliability every time." },
    { holderClass: "img-wrapper-1", effect: "fade-right", descclass: "desc2", class: "class2", left: false, step: "02", icon: sorting, heading: "Sorting", desc: "Laundry is carefully sorted depending on the fabric type, colour, and cleaning requirements to ensure optimal care and washing results." },
    { holderClass: "img-wrapper-2", effect: "fade-left", descclass: "desc3", class: "class3", left: true, step: "03", icon: Cleaning, heading: "Cleaning", desc: "Each item is then cleaned using advanced techniques and eco-friendly detergents, tailored to suit the fabric type for exceptional results." },
    { holderClass: "img-wrapper-3", effect: "fade-right", descclass: "desc4", class: "class4", left: false, step: "04", icon: Ironing, heading: "Ironing", desc: "Clothes are professionally ironed to perfection, ensuring a crisp, crease-free and polished look, ready for immediate wear or storage." },
    { holderClass: "img-wrapper-4", effect: "fade-left", descclass: "desc5", class: "class5", left: true, step: "05", icon: Folding, heading: "Folding", desc: "Freshly cleaned and ironed items are neatly folded or returned on hangers with care to maintain their quality and prevent creasing during storage." },
    { holderClass: "img-wrapper-5", effect: "fade-right", descclass: "desc6", class: "class6", left: false, step: "06", icon: qualitycheck, heading: "Quality Check", desc: "Every item undergoes a thorough inspection to ensure it meets our high standards for cleanliness, appearance, and overall condition." },
    { holderClass: "img-wrapper-6", effect: "fade-left", descclass: "desc7", class: "class7", left: true, step: "07", icon: Packing, heading: "Packing", desc: "Cleaned and inspected items are carefully packed in protective coverings to maintain their freshness and prevent damage during transit." },
    { holderClass: "img-wrapper-7", effect: "fade-right", descclass: "desc8", class: "class8", left: false, step: "08", icon: inspection, heading: "Final Inspection", desc: "A final check is performed on the packed items to ensure accuracy and quality, and that nothing has been overlooked." },
    { holderClass: "img-wrapper-8", effect: "fade-left", descclass: "desc9", class: "class9", left: true, step: "09", icon: Delivery, heading: "Delivery", desc: "Your perfectly cleaned and packaged laundry is delivered on time to your doorstep, ensuring a smooth process and guaranteed customer satisfaction." },
  ];
  const [offset, setOffset] = useState(0);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        setOffset(scrollContainerRef.current.scrollTop);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, [offset]);

  const howItWorkRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setupAnimation(); // Run your GSAP animation setup
          }
        });
      },
      {
        root: null,
        rootMargin: "-120px 0px 0px 0px",
        threshold: 0.1,
      }
    );

    if (howItWorkRef.current) {
      observer.observe(howItWorkRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const setupAnimation = () => {
    const pathElement = document.querySelector(".heelloo");

    if (!pathElement) {
      setTimeout(setupAnimation, 100);
      return;
    }

    gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

    gsap.set("#motionSVG", { scale: 1.2, autoAlpha: 1 });
    gsap.set("#tractor", { transformOrigin: "50% 50%" });

    const animation = gsap.to("#tractor", {
      scrollTrigger: {
        trigger: ".heelloo",
        start: "top center",
        end: () => "+=" + pathElement.getBoundingClientRect().height,
        scrub: 3,
        markers: false,
      },
      ease: pathEase(".heelloo"),
      immediateRender: true,
      motionPath: {
        path: ".heelloo",
        align: ".heelloo",
        alignOrigin: [0.5, 0.5],
        autoRotate: -80,
      },
    });

    function pathEase(path, config = {}) {
      let axis = config.axis || "y",
        precision = config.precision || 1,
        rawPath = MotionPathPlugin.cacheRawPathMeasurements(MotionPathPlugin.getRawPath(gsap.utils.toArray(path)[0]), Math.round(precision * 12)),
        useX = axis === "x",
        start = rawPath[0][useX ? 0 : 1],
        end = rawPath[rawPath.length - 1][rawPath[rawPath.length - 1].length - (useX ? 2 : 1)],
        range = end - start,
        l = Math.round(precision * 200),
        inc = 1 / l,
        positions = [0],
        a = [],
        minIndex = 0,
        smooth = [0],
        minChange = (1 / l) * 0.6,
        smoothRange = config.smooth === true ? 7 : Math.round(config.smooth) || 0,
        fullSmoothRange = smoothRange * 2,
        getClosest = (p) => {
          while (positions[minIndex] <= p && minIndex++ < l) {}
          a.push(((p - positions[minIndex - 1]) / (positions[minIndex] - positions[minIndex - 1])) * inc + minIndex * inc);
          smoothRange && a.length > smoothRange && a[a.length - 1] - a[a.length - 2] < minChange && smooth.push(a.length - smoothRange);
        },
        i = 1;
      for (; i < l; i++) {
        positions[i] = (MotionPathPlugin.getPositionOnPath(rawPath, i / l)[axis] - start) / range;
      }
      positions[l] = 1;
      for (i = 0; i < l; i++) {
        getClosest(i / l);
      }
      a.push(1); // must end at 1.
      if (smoothRange) {
        // smooth at the necessary indexes where a small difference was sensed. Make it a linear change over the course of the fullSmoothRange
        smooth.push(l - fullSmoothRange + 1);
        smooth.forEach((i) => {
          let start = a[i],
            j = Math.min(i + fullSmoothRange, l),
            inc = (a[j] - start) / (j - i),
            c = 1;
          i++;
          for (; i < j; i++) {
            a[i] = start + inc * c++;
          }
        });
      }
      return (p) => {
        let i = p * l,
          s = a[i | 0];
        return i ? s + (a[Math.ceil(i)] - s) * (i % 1) : 0;
      };
    }

    return () => {
      if (animation) animation.kill();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  };

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");
    const imgWrap = document.querySelector(".img-wrapper");
    const imgItem = document.querySelector(".img-placeholder img");

    function moveImg(e) {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const tl = gsap.timeline();
      tl.to(imgWrap, {
        duration: 0.4,
        x: mouseX,
        y: mouseY,
        ease: "expo.out",
      });
    }

    function linkHover(e) {
      const imgSrc = e.target.dataset.src;
      const tl = gsap.timeline();
      if (e.type === "mouseenter") {
        tl.set(imgItem, {
          attr: { src: imgSrc },
        }).to(imgWrap, {
          autoAlpha: 1,
          scale: 1,
        });
      } else if (e.type === "mouseleave") {
        tl.to(imgWrap, {
          autoAlpha: 0,
          scale: 0.3,
        });
      }
    }

    function initAnimation() {
      navLinks.forEach((link) => {
        link.addEventListener("mouseenter", linkHover);
        link.addEventListener("mouseleave", linkHover);
        link.addEventListener("mousemove", moveImg);
      });
    }

    function init() {
      initAnimation();
    }

    init();

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("mouseenter", linkHover);
        link.removeEventListener("mouseleave", linkHover);
        link.removeEventListener("mousemove", moveImg);
      });
    };
  }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link-1");
    const imgWrap = document.querySelector(".img-wrapper-1");
    const imgItem = document.querySelector(".img-placeholder img");

    function moveImg(e) {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const tl = gsap.timeline();
      tl.to(imgWrap, {
        duration: 0.4,
        x: mouseX,
        y: mouseY,
        ease: "expo.out",
      });
    }

    function linkHover(e) {
      const imgSrc = e.target.dataset.src;
      const tl = gsap.timeline();
      if (e.type === "mouseenter") {
        tl.set(imgItem, {
          attr: { src: imgSrc, alt: "how it works" , title: "how it works" },
        }).to(imgWrap, {
          autoAlpha: 1,
          scale: 1,
        });
      } else if (e.type === "mouseleave") {
        tl.to(imgWrap, {
          autoAlpha: 0,
          scale: 0.3,
        });
      }
    }

    function initAnimation() {
      navLinks.forEach((link) => {
        link.addEventListener("mouseenter", linkHover);
        link.addEventListener("mouseleave", linkHover);
        link.addEventListener("mousemove", moveImg);
      });
    }

    function init() {
      initAnimation();
    }

    init();

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("mouseenter", linkHover);
        link.removeEventListener("mouseleave", linkHover);
        link.removeEventListener("mousemove", moveImg);
      });
    };
  }, []);

  const [isHovered, setIsHovered] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Mouse event handlers
  const handleMouseEnter = (e) => {
    setIsHovered(true);
    updateCursorPosition(e);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseMove = (e) => {
    updateCursorPosition(e);
  };

  const updateCursorPosition = (e) => {
    setCursorPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  useEffect(() => {
    document.addEventListener("mousemove", updateCursorPosition);
    return () => {
      document.removeEventListener("mousemove", updateCursorPosition);
    };
  }, []);

  return (
    <div ref={scrollContainerRef}>
      <div ref={howItWorkRef} id="HowItWork">
        <div id="how-it-works">
          <div className="container container-cut position-relative">
            <div className="text-wrapper-1111 d-flex justify-content-center">
              <h2 className="fw-bold text-dark">HOW IT WORKS</h2>
            </div>

            <div
              style={{
                top: "230px",
                left: "100px",
                position: "absolute",
                width: "100px",
                transition: "transform 0.1s ease-out",
              }}
              className="rotate-car"
            >
              <ReactSVG src={car} />
            </div>
            <ul style={{ listStyle: "none" }}>
              <CursorPointer z={zIndexZ} x={cursorPosition.x} y={cursorPosition.y} visible={isHovered} />

              <div className="working row ms-md-5">
                {dataLeft &&
                  dataLeft.map((item, idx) => (
                    <>
                      <div className="container">
                        <div className="img-wrapper">
                          <div className="img-placeholder">
                            <div style={{ zIndex: 9, width: "15vw", height: "15vw", backgroundColor: "transparent" }}></div>
                            <img src="" width="100px" height="100px" alt="Placeholder" title="Placeholder" />
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <div className="img-wrapper-1">
                          <div className="img-placeholder">
                            <div style={{ zIndex: 9, width: "15vw", height: "15vw", backgroundColor: "green" }}></div>
                            <img src="" width="100px" height="100px" alt="Placeholder" title="Placeholder" />
                          </div>
                        </div>
                      </div>
                      {item.left === true ? (
                        <div className={`work-step col-lg-6 col-3 col-md-4 d-block ${item.class}`}>
                          <div className="steps-1">{item.step}</div>
                          <div className="car-icon">{/* <img style={{ width: "100px", height: "100px", objectFit: "contain" }} src={require("../../../../Assets/svgs/How-it-works/Group.png")} /> */}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      <li className="collection col-lg-6 col-md-8 col-9" data-aos={item.effect} style={{ zIndex: "9999" }}>
                        <div className={`${item.descclass}`}>
                          <div className={`custom-icon `}>
                            <h3><ReactSVG src={item.icon} /></h3>
                          </div>
                          <div className="nav-link" data-src="https://images.unsplash.com/photo-1561070791-2526d30994b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80">
                            <h3 onMouseEnter={windowWidth > 992 ? handleMouseEnter : ""} onMouseLeave={windowWidth > 992 ? handleMouseLeave : ""} onMouseMove={windowWidth > 992 ? handleMouseMove : ""} className="my-3 fw-bold stroke-text">
                              {item.heading}
                            </h3>
                            <p>{item.desc}</p>
                          </div>
                        </div>
                      </li>
                      <>
                        {item.left === false ? (
                          <div className={`work-step col-lg-6 col-3 col-md-4 d-block ${item.class}`}>
                            <div className="d-flex justify-content-end">
                              <div className="steps-1">{item.step}</div>
                              <div className="car-icon">{/* <img style={{ width: "100px", height: "100px", objectFit: "contain" }} src={require("../../../../Assets/svgs/How-it-works/Group.png")} /> */}</div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    </>
                  ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWork;
